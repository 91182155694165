import React from "react";
import { Outlet } from "react-router-dom";
import LanguageBar from "../LanguageBar/languagebar";

import Footer from "../footer/footer";
import Header from "../header/header";


function LayoutPageNotFound() {
  return (
    <div>
      <LanguageBar />
      <Header />
      <div>
        <Outlet />
      </div>
      <Footer/>
    </div>
  );
}

export default LayoutPageNotFound;
