// NavBarData.js
const navLinks = [
  { title: "Home", path: "/rmifc" },

  {
    title: "About Us",
    path: "/About Us RMIFC",
    subLinks: [
      { name: "About Us", link: "About Us RMIFC" },
      { name: "Director", link: "Director RMIFC" },
      { name: "IOC", link: "IOC RMIFC" },
      { name: "Our teams", link: "Our teams RMIFC" },
      { name: "Our partners", link: "Our partners RMIFC" },
      { name: "Testimonials", link: "Testimonials RMIFC" },
    ],
  },
  {
    title: "Our Work RMIFC",
    path: "/Our Work RMIFC",
    subLinks: [
      { name: "Capacity building", link: "Capacity building RMIFC" },
      {
        name: "Coordination of regional operations at sea",
        link: "Coordination of regional operations at sea RMIFC",
      },
      {
        name: "Regional tabletop exercises (TTX)",
        link: "Regional tabletop exercises TTX RMIFC",
      },
      {
        name: "Field Training Exercises (FTX) RMIFC Achievements",
        link: "Field Training Exercises FTX RMIFC Achievements RMIFC",
      },
    ],
  },
  {
    title: "Media Center-RMIFC",
    path: "/Media Center RMIFC",
    subLinks: [
      { name: "News", link: "News RMIFC" },
      { name: "Blog", link: "Blog RMIFC" },
      { name: "Gallery", link: "Gallery RMIFC" },
      { name: "Videos", link: "Videos RMIFC" },
    ],
  },
  {
    title: "Resources",
    path: "/Resources RMIFC",
    subLinks: [
      { name: "Case studies", link: "Case studies RMIFC" },
      { name: "General reports", link: "General reports RMIFC" },
      { name: "Research articles-RMIFC", link: "Research articles RMIFC" },
      { name: "White Papers", link: "White Papers RMIFC" },
    ],
  },
  { title: "Events", path: "/Events RMIFC" },
  {
    title: "Governance Framework-RMIFC",
    path: "/Governance Framework RMIFC",
    subLinks: [
      { name: "Regional agreements", link: "Regional agreements RMIFC" },
      { name: "Council of Ministers", link: "Council-of-Ministers-RMIFC" },
      { name: "Steering committee", link: "Steering committee RMIFC" },
      { name: "Signatory States", link: "Signatory States RMIFC" },
      { name: "Trust fund", link: "Trust fund RMIFC" },
      { name: "Secretariat", link: "Secretariat RMIFC" },
    ],
  },
  { title: "Contact us", path: "/Contact us RMIFC" },
  { title: "[RCOC Centre]", path: "/rcoc" },
];

export default navLinks;
