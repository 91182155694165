import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import { Link } from "react-router-dom";

const RCOCAboutUsFR = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>About Us</h1>

            <p>
              Le RCCO a été créé dans le cadre de l'Accord régional sur la
              coordination des opérations offshore dans l'ouest de l'océan
              Indien développé dans le cadre du programme MASE mis en œuvre par
              la Commission de l'océan Indien avec un financement de l'Union
              européenne. L'Union des Comores, la République de Djibouti, la
              République de Madagascar, la République de Maurice et la
              République des Seychelles ont signé l'Accord régional en avril
              2018. Deux autres pays ont rejoint l'Accord en novembre 2018, la
              République française et la République de Kenya.
            </p>
            <p>
              Les parties signataires de l'Accord étaient convenues d'établir un
              système de coordination opérationnelle régionale ayant les
              objectifs suivants :
            </p>

            <div>
              <Link to="/About-Us-RMIFCFR">
                <button>En savoir plus</button>
              </Link>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/rcoc/homepage/aboutus.jpg"
                alt="Regional Coordination Operations Centre (RCOC)"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RCOCAboutUsFR;
