import React from "react";
import { Outlet } from "react-router-dom";
import LanguageBarCentersRCOC from "../../LanguageBar/languagebarcentersrcoc";
import FooterComp from "../../../rcoc/footer/footercomp";

import RcocHeader3 from "./rcocheader3";
import LeftMenus from "./leftMenusrcoc";

function RCOCLayout3() {
  return (
    <div>
      |<RcocHeader3 />
      <LeftMenus />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RCOCLayout3;
