import React from "react";
import { Outlet } from "react-router-dom";
import LanguageBarCentersRCOC from "../../LanguageBar/languagebarcentersrcoc";
import FooterComp from "../../../rcoc/footer/footercomp";
import RcocHeader from "./rcocheader";

function RCOCLayout2() {
  return (
    <div>
      |<RcocHeader />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RCOCLayout2;
