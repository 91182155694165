import React from "react";
import NotFoundComp from "./components/notfound/notfoundcomp";

function Nomatch() {
  return (
    <div>
      <NotFoundComp />
    </div>
  );
}

export default Nomatch;
