import React from "react";
import MainCenters from "./components/centers/maincentersfr";
import Sponsors from "./components/sponsors/sponsors";


const HomeFr = () => {
  return (
    <div>
      <MainCenters />
      <Sponsors />
    </div>
  );
};

export default HomeFr;
