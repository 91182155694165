import React from "react";
import { Outlet } from "react-router-dom";
import FooterComp from "../../../rmifc/footer/footercomp";

import RmifcHeader3 from "./rmifcheader3";
import LeftMenus from "./leftMenusrmifc";

function RCOCLayout3() {
  return (
    <div>
      |<RmifcHeader3 />
      <LeftMenus />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RCOCLayout3;
