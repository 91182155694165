// NavBarData.js
const navLinks = [
  { title: "Home", path: "/rmifcfr" },

  {
    title: "À propos de nous",
    path: "/À propos de nous RMIFC",
    subLinks: [
      { name: "À propos de nous", link: "À propos de nous RMIFC" },
      { name: "Directeur", link: "Directeur RMIFC" },
      { name: "IOC", link: "Iocfr RMIFC" },
      { name: "Nos équipes", link: "Nos équipes RMIFC" },
      { name: "Nos partenaires", link: "Nos partenaires RMIFC" },
      { name: "Témoignages", link: "Témoignages RMIFC" },
    ],
  },
  {
    title: "Notre travail",
    path: "/Notre travail RMIFC",
    subLinks: [
      {
        name: "Renforcement des capacités",
        link: "Renforcement des capacités RMIFC",
      },
      {
        name: "Coordination des opérations régionales en mer",
        link: "Coordination des opérations régionales en mer RMIFC",
      },
      {
        name: "Exercices régionaux sur table (TTX)",
        link: "Exercices régionaux sur table TTX RMIFC",
      },
      {
        name: "Exercices d'entraînement sur le terrain (FTX) Réalisations du RCOC",
        link: "Exercices d'entraînement sur le terrain FTX Réalisations du RCOCRMIFC",
      },
    ],
  },
  {
    title: "Centre des médias",
    path: "/Centre des médias RMIFC",
    subLinks: [
      { name: "Actualités", link: "Actualités RMIFC" },
      { name: "Blog", link: "Blog RMIFC" },
      { name: "Galerie", link: "Galerie RMIFC" },
      { name: "Vidéos", link: "Vidéos RMIFC" },
    ],
  },
  {
    title: "Ressources",
    path: "/Ressources RMIFC",
    subLinks: [
      { name: "Etudes de cas", link: "Etudes de cas RMIFC" },
      { name: "Rapports généraux", link: "Rapports généraux RMIFC" },
      { name: "Articles de recherche", link: "Articles de recherche RMIFC" },
      { name: "Livres blancs", link: "Livres blancs RMIFC" },
    ],
  },
  { title: "Événements", path: "/Événements RMIFC" },
  {
    title: "Cadre de gouvernance",
    path: "/Cadre-de-gouvernance RMIFC",
    subLinks: [
      { name: "Accords régionaux", link: "Accords régionaux RMIFC" },
      { name: "Conseil des ministres", link: "Conseil des ministres RMIFC" },
      { name: "Comité de pilotage", link: "Comité de pilotage RMIFC" },
      { name: "États signataires", link: "États signataires RMIFC" },
      { name: "Fonds fiduciaire", link: "Fonds fiduciaire RMIFC" },
      { name: "Secrétariat", link: "Secrétariat RMIFC" },
    ],
  },
  { title: "Contactez-nous", path: "/Contactez nous RMIFC" },
  { title: "[RCOC Centre]", path: "/rcoc" },
];

export default navLinks;
