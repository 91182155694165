// NavBarData.js
const navLinksMobile = [
  { title: "Home", path: "/" },
  {
    title: "About Us",
    path: "/aboutusrcoc",
  },
  {
    title: "Our Work",
    path: "/Our Work",
  },
  {
    title: "Media Center",
    path: "/Media Center",
  },
  {
    title: "Resources",
    path: "/Resources",
  },
  {
    title: "Events",
    path: "/Events",
  },
  {
    title: "Gorvenance Framework",
    path: "/Gorvenance Framework",
  },

  { title: "Contuct us", path: "/Contuct us" },
];

export default navLinksMobile;
