import React from "react";

import MainCenters from "./components/centers/maincenters";
import Sponsors from "./components/sponsors/sponsors";


const Home = () => {
  return (
    <div>
      <MainCenters />
      <Sponsors />

    </div>
  );
};

export default Home;
