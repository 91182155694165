import React from "react";
import CapacityBuilding from "./capacitybuilding";

const OurWorkIndex = () => {
  return (
    <div>
      <CapacityBuilding />
    </div>
  );
};

export default OurWorkIndex;
