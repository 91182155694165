import React from "react";
import CapacityBuilding from "./capacitybuilding";

const OurWorkIndexRMIFC = () => {
  return (
    <div>
      <CapacityBuilding />
    </div>
  );
};

export default OurWorkIndexRMIFC;
