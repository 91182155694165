import React from "react";
import { Outlet } from "react-router-dom";
import FooterComp from "../../../rcoc/footer/footercomp";
import LeftMenusFR from "./leftMenusrcocfr";
import RcocHeader4FR from "./rcocheader4fr";

function RCOCLayout4FR() {
  return (
    <div>
      |<RcocHeader4FR />
      <LeftMenusFR />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RCOCLayout4FR;
