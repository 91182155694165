import React from "react";
import AboutUs from "./homepage/aboutus";
import RMIFCDirectors from "./homepage/directors";
import RMIFCCoordinator from "./homepage/coordinator";
import RMIFCMission from "./homepage/ourmission/rmifcmission.js";
import RMIFCMemberStates from "./homepage/memberstate/memberstates";
import RMIFCPartnerILO from "./homepage/partnerILO/partnerilo";
import RMIFCNEWS from "./homepage/newsbytes/rmifcnews.js";
import RMIFCFeaturedStory from "./homepage/featuredstory/featuredstory";
import SignUp from "./signup/signup";
import RMIFCMemberStatesFlags from "./homepage/memberstate/memberstatesflags.js";
import RMIFCPartnerFlags from "./homepage/partnerILO/partnerflags.js";
import HomeSlider from "../rmifc/slider/homeslider";

const RMIFCHomePage = () => {
  return (
    <div>
      <HomeSlider />
      <AboutUs />
      <RMIFCDirectors />
      <RMIFCCoordinator />
      <RMIFCMission />
      <RMIFCMemberStates />
      <RMIFCPartnerILO />
      <RMIFCNEWS />
      <RMIFCFeaturedStory />
      <RMIFCMemberStatesFlags />
      <RMIFCPartnerFlags />
      <SignUp />
    </div>
  );
};

export default RMIFCHomePage;
