import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

function LanguageBar() {
  return (
    <div>
      <div className={Styles.mainContainer}>
        <Link to="/">
          <p>English</p>
        </Link>
        <Link to="/fr">
          <p>French</p>
        </Link>
      </div>
      
    </div>
  );
}

export default LanguageBar;
