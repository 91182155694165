import React from "react";
import NavBar from "../../../rcoc/navbar/navbar";
import LanguageBarCenters from "../../LanguageBar/rcoclanguagebarcenters";
import Styles from "./header.module.css";

function RcocHeader3() {
  return (
    <div className={Styles.mainContainer}>
      <LanguageBarCenters />
      <NavBar />
    </div>
  );
}

export default RcocHeader3;
