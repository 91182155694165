import React from "react";
import Styles from "./Styles.module.css";
import SkeletonDisplay from "../../hooks/skeletonDisplay";
import { Link } from "react-router-dom";

const RCOCAboutUs = () => {
  return (
    <div className={Styles.slider2}>
      <div className={Styles.mainContainer}>
        <div className={Styles.container}>
          <div className={Styles.productCard}>
            <h1>About Us</h1>

            <p>
              The RMIFC was established within the framework of the Regional
              Agreement on the Coordination of Offshore Operations in the
              Western Indian Ocean developed under the MASE programme
              implemented by the Indian Ocean Commission with funding from the
              European Union. The Union of Comoros, the Republic of Djibouti,
              the Republic of Madagascar, the Republic of Mauritius and the
              Republic of Seychelles, signed the Regional Agreement in April
              2018. Two other countries joined the Agreement in November 2018,
              the French Republic and the Republic of Kenya.{" "}
            </p>
            <p>
              The signatory parties to the Agreement had agreed to establish a
              regional operational coordination system with the following
              objectives:
            </p>

            <div>
              <Link to="/About-Us-RMIFC">
                <button>Read More</button>
              </Link>
            </div>
          </div>
          <div className={Styles.productCard}>
            <SkeletonDisplay>
              <img
                src="/rcoc/homepage/aboutus.jpg"
                alt="Regional Coordination Operations Centre (RCOC)"
              />
            </SkeletonDisplay>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RCOCAboutUs;
