import React from "react";

import AboutUs from "./homepage/aboutusfr";
import RMIFCDIRECRORSFR from "./homepage/directorsfr";
import RMIFCCOORDINATORFR from "./homepage/coordinatorFR";
import RMIFCMissionFR from "./homepage/ourmission/rmifcmissionFR";
import RMIFCMemberStatesFR from "./homepage/memberstate/memberstatesfr";
import RMIFCPartnerILOFR from "./homepage/partnerILO/partnerilofr";
import RMIFCNEWSFR from "./homepage/newsbytes/rmifcnewsfr";
import RMIFCFeaturedStoryFR from "./homepage/featuredstory/featuredstoryfr";
import RMIFCMemberStatesFlagsFR from "./homepage/memberstate/memberstatesflagsfr";
import RMIFCPartnerFlagsFR from "./homepage/partnerILO/partnerflagsfr";
import SignUpFR from "./signup/signupfr";
import HomeSliderFR from "../rmifc/slider/homesliderfr";
const RMIFCHomePageFR = () => {
  return (
    <div>
      <HomeSliderFR />
      <AboutUs />
      <RMIFCDIRECRORSFR />
      <RMIFCCOORDINATORFR />
      <RMIFCMissionFR />
      <RMIFCMemberStatesFR />
      <RMIFCPartnerILOFR />
      <RMIFCNEWSFR />
      <RMIFCFeaturedStoryFR />
      <RMIFCMemberStatesFlagsFR />
      <RMIFCPartnerFlagsFR />
      <SignUpFR />
    </div>
  );
};

export default RMIFCHomePageFR;
