import React from "react";
import { Outlet } from "react-router-dom";
import LanguageBar from "../../LanguageBar/languagebar";

import Footer from "../../footer/footer";
import Header from "../../header/header";
import HomeSlider from "../../homeslider/homeslider";

function Layout() {
  return (
    <div>
      <LanguageBar />
      <Header />
      <HomeSlider />
      <div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
