import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

function CapacityBuildingRMIFC() {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.left}></div>
      <div className={Styles.right}>
        <h1>Capacity Building</h1>
        <p>
          The RMIFC was established within the framework of the Regional
          Agreement on the Coordination of Offshore Operations in the Western
          Indian Ocean developed under the MASE programme implemented by the
          Indian Ocean Commission with funding from the European Union. The
          Union of Comoros, the Republic of Djibouti, the Republic of
          Madagascar, the Republic of Mauritius and the Republic of Seychelles,
          signed the Regional Agreement in April 2018. Two other countries
          joined the Agreement in November 2018, the French Republic and the
          Republic of Kenya.{" "}
        </p>

        <p>
          The signatory parties to the Agreement had agreed to establish a
          regional operational coordination system with the following
          objectives:
        </p>
        {Objectives.map((objective, index) => (
          <li key={index}>{objective}</li>
        ))}
        <p>
          For the implementation of the Agreement, the Regional Operations
          Coordination Centre (RCCO) has been established.{" "}
        </p>
        <p>
          The RCCO enjoys legal personality under domestic and international
          law, as well as the protections, privileges and immunities provided
          for in the Headquarters Agreement signed on 1 July 2017 between the
          Indian Ocean Commission and the Republic of Seychelles
        </p>
        <p>
          It works closely with the Regional Information Fusion Centre (RMIFC),
          based in Madagascar.
        </p>
        <p>
          Each State Party shall appoint a liaison officer within the RCCO, in
          order to establish a link between the national structures and the
          Centre.{" "}
        </p>
        <p>
          The Centre is governed by the rules indicated by the "Governance of
          Regional Centres". The RCCO was established in 2017 and only became
          fully operational in 2019 after the assignment of the liaison
          officers.
        </p>
      </div>
    </div>
  );
}

export default CapacityBuildingRMIFC;

const Objectives = [
  "To promote cooperation and coordination to enable the parties' law enforcement officials, vessels and aircraft to conduct coordinated missions of regional interest in maritime areas under the jurisdiction of the parties and on the high seas in accordance with international law, to their legislature",
  "Strengthen individual and institutional capacities through training and experience exchange programmes",
  " Organize joint and/or coordinated interventions at sea and in the surrounding space to prevent and stop acts or situations affecting maritime safety and security",
  " To enable and facilitate mutual support between States in the region when an international or regional maritime security interest is affected in the regional area of operation",
  "Promote cooperation with other agencies operating in the field of maritime safety and security.",
];
