import React from "react";
import Styles from "./Styles.module.css";

function CapacityBuildingRMIFCFR() {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.left}></div>
      <div className={Styles.right}>
        <h1>Renforcement des capacités</h1>
        <p>
          Le RCCO a été créé dans le cadre du Conseil Régional Accord sur la
          coordination des opérations offshore dans l'Ouest Océan Indien
          développé dans le cadre du programme MASE mis en œuvre par le
          Commission de l'Océan Indien avec un financement de l'Union
          européenne. Le Union des Comores, la République de Djibouti, la
          République de Madagascar, la République de Maurice et la République
          des Seychelles, ont signé l’accord régional en avril 2018. Deux autres
          pays ont adhéré à l’Accord en novembre 2018, la République française
          et le République du Kenya.{" "}
        </p>

        <p>
          Les parties signataires de l'accord étaient convenues d'établir un
          système de coordination opérationnelle régionale avec les éléments
          suivants objectifs :
        </p>
        {Objectives.map((objective, index) => (
          <li key={index}>{objective}</li>
        ))}
        <p>
          Pour la mise en œuvre de l'Accord, les Opérations Régionales Le centre
          de coordination (RCCO) a été créé.{" "}
        </p>
        <p>
          Le RCCO jouit de la personnalité juridique au plan national et
          international. la loi, ainsi que les protections, privilèges et
          immunités accordés comme le prévoit l'accord de siège signé le 1er
          juillet 2017 entre les Commission de l'Océan Indien et République des
          Seychelles
        </p>
        <p>
          Il travaille en étroite collaboration avec le Centre Régional de
          Fusion d'Informations (CRFIM), basé à Madagascar.
        </p>
        <p>
          Chaque État partie nomme un officier de liaison au sein du RCCO, en
          afin d'établir un lien entre les structures nationales et les Centre.{" "}
        </p>
        <p>
          Le Centre est régi par les règles indiquées par la « Gouvernance des
          Centres régionaux". Le RCCO a été créé en 2017 et n'est devenu
          pleinement opérationnel en 2019 après l’affectation de l’agent de
          liaison officiers.
        </p>
      </div>
    </div>
  );
}

export default CapacityBuildingRMIFCFR;

const Objectives = [
  "Promouvoir la coopération et la coordination pour permettre aux agents chargés de l'application des lois, aux navires et aux aéronefs des parties de mener des missions coordonnées d'intérêt régional dans les zones maritimes sous la juridiction des parties et en haute mer, conformément au droit international, auprès de leur corps législatif",
  "Renforcer les capacités individuelles et institutionnelles à travers des programmes de formation et d'échange d'expériences",
  " Organiser des interventions conjointes et/ou coordonnées en mer et dans l'espace environnant pour prévenir et faire cesser des actes ou des situations affectant la sûreté et la sécurité maritimes",
  " Permettre et faciliter le soutien mutuel entre les États de la région lorsqu'un intérêt international ou régional en matière de sécurité maritime est affecté dans la zone régionale d'opération ",
  "Promouvoir la coopération avec d'autres agences opérant dans le domaine de la sûreté et de la sécurité maritimes.",
];
