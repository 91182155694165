import React from "react";
import Styles from "./Styles.module.css";
import MainCenters from "../centers/maincenters";

function NotFoundComp() {
  return (
    <div className={Styles.mainContainer}>
      <h6>404</h6>
      <h6>
        Page not found <span style={{ color: "blue" }}>[Page introuvable]</span>
      </h6>
      <div className={Styles.themesContainer}>
        <div className={Styles.productCard}>
          {" "}
          <p>
            Sorry, the page you were looking for doesn't exist. We might have
            broken this link or deleted this content.
          </p>
          <p style={{ color: "blue" }}>
            [Désolé, la page que vous recherchiez n'existe pas. Nous avons
            peut-être rompu ce lien ou supprimé ce contenu.]
          </p>
          <p>
            Go to the homepage(s) for the Centres using the links below or
            contact us if you feel you've received this message in error.
          </p>
          <p style={{ color: "blue" }}>
            [Accédez à la ou aux pages d'accueil des centres en utilisant les
            liens ci-dessous ou contactez-nous si vous pensez avoir reçu ce
            message par erreur.]
          </p>
        </div>
        <div className={Styles.productCard}>
          <img src="/images/notfound.png" alt="RMIFC/RCOC"/> 
        </div>
      </div>

      <MainCenters />
    </div>
  );
}

export default NotFoundComp;
