import React from "react";
import Styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { AttentionSeeker } from "react-awesome-reveal";

function MainMenu() {
  return (
    <>
      <div className={Styles.Navbar}>
        <div className={Styles.leftSide}>
          <Link to="/rcocfr">
            <img
              src="/header/rcoc.png"
              alt="Centre régional de coordination des opérations (RCOC)"
            />
          </Link>
        </div>
        <div className={Styles.centerdiv}>
          <AttentionSeeker>
            <Link to="/">
              <h1>Centre régional de coordination des opérations (RCOC) </h1>
            </Link>
          </AttentionSeeker>
        </div>
        <div className={Styles.rightSide}>
          <button>Portail des membres</button>
        </div>
      </div>
    </>
  );
}

export default MainMenu;
