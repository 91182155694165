import React from "react";
import { Link, useLocation } from "react-router-dom";
import Styles from "./leftmenus.module.css";

const LeftMenus = () => {
  const location = useLocation();
  const rawPath = decodeURIComponent(location.pathname.replace(/^\/+/, ""));

  // Treat these paths as "Home"
  const normalizedPath =
    rawPath === "" || rawPath === "rcoc" || rawPath === "About Us"
      ? "About Us"
      : rawPath;

  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.left}>
        <div className={Styles.menu}>
          {menuItems.map((item, index) => {
            const isActive = normalizedPath === item.menuLink;
            return (
              <Link to={`/${item.menuLink}`} key={index}>
                <li className={isActive ? Styles.active : ""}>
                  {item.menuName}
                </li>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LeftMenus;

const menuItems = [
  { menuName: "Home", menuLink: "rcoc" },
  { menuName: "About Us", menuLink: "About Us" },
  { menuName: "Director", menuLink: "Director" },
  { menuName: "IOC", menuLink: "Ioc" },
  { menuName: "Our Teams", menuLink: "Our Teams" },
  { menuName: "Our Partners", menuLink: "Our Partners" },
  { menuName: "Testimonials", menuLink: "Testimonials" },
];
