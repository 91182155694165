import React from "react";
import Styles from "./styles.module.css";

const MobileMenu = () => {
  return (
    <div className={Styles.rightsidemobile}>
      <button>Members Portal</button>
    </div>
  );
};

export default MobileMenu;
