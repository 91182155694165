import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Nomatch from "./nomatch.js";
import Home from "./home";
import HomeFR from "./homefr.js";
import LayoutPageNotFound from "./components/Layout/layoutpagenotfound.js";

// Layout>
import Layout from "./components/Layout/home/layout.js";
import LayoutFR from "./components/Layout/home/layoutfr.js";
import RCOCLayout2 from "./components/Layout/rcocaboutus/rcoclayout2.js";
import RCOCLayout2FR from "./components/Layout/rcocaboutus/rcoclayout2fr.js";
import RMIFCLayout2 from "./components/Layout/rmifcaboutus/rmifclayout2.js";
import RMIFCLayout2FR from "./components/Layout/rmifcaboutus/rmifclayout2fr.js";
// RCOC
import RCOCHomePage from "./rcoc/index.js";
import RCOCHomePageFR from "./rcoc/indexfr.js";

// RMIFC
import RMIFCHomePage from "./rmifc/index.js";
import RMIFCHomePageFR from "./rmifc/indexfr.js";
import RMIFCAboutUsIndex from "./rmifc/aboutus/rmifcindex.js";
import RMIFCAboutUsIndexFR from "./rmifc/aboutus/rmifcindexfr.js";

//Aboutus RCOC
import RCOCAboutUsIndex from "./rcoc/aboutus/rcocindex.js";
import RCOCAboutUsIndexFR from "./rcoc/aboutus/rcocindexfr.js";
import IOCComp from "./rcoc/aboutus/ioc.js";
import RCOCLayout3 from "./components/Layout/rcocaboutus/rcoclayout3.js";
import OurTeams from "./rcoc/aboutus/ourteams.js";
import OurPartners from "./rcoc/aboutus/ourpartners.js";
import Testmonials from "./rcoc/aboutus/testmonials.js";
import RCOCLayout3FR from "./components/Layout/rcocaboutus/rcoclayout3FR.js";
import Director from "./rcoc/aboutus/director.js";
import DirectorFR from "./rcoc/aboutus/directorfr.js";
import IOCFR from "./rcoc/aboutus/iocfr.js";
import OurTeamsFR from "./rcoc/aboutus/ourteamsfr.js";
import OurPatnersFR from "./rcoc/aboutus/ourpartnersfr.js";
import TestMonialsFR from "./rcoc/aboutus/testmonialsfr.js";

//Aboutus RMIFC

import RMIFCLayout3 from "./components/Layout/rmifcaboutus/rmifclayout3.js";
import RCOCLayout4 from "./components/Layout/rcocourwork/rcoclayout4.js";
import OurWorkIndex from "./rcoc/ourwork/ourworkindex.js";
import CapacityBuilding from "./rcoc/ourwork/capacitybuilding.js";
import OurWorkIndexFR from "./rcoc/ourwork/ourworkindexfr.js";
import CapacityBuildingFR from "./rcoc/ourwork/capacitybuildingfr.js";
import RCOCLayout4FR from "./components/Layout/rcocourwork/rcoclayout4FR.js";
import CoordinationofRegionalOperationsatSeaFR from "./rcoc/ourwork/coordinationofregionaloperationsatseafr.js";
import CoordinationofRegionalOperationsatSea from "./rcoc/ourwork/coordinationofregionaloperationsatsea.js";
import RegionalTableopExercisesTTX from "./rcoc/ourwork/regionaltabletopexercisesttx.js";
import RegionalTableopExercisesTTXFR from "./rcoc/ourwork/regionaltabletopexercisesttxfr.js";
import FieldTrainingExercisesFTXRCOCAchievements from "./rcoc/ourwork/fieldtrainingexercisesftxrcocachievements.js";
import FieldTrainingExercisesFTXRCOCAchievementsFR from "./rcoc/ourwork/fieldtrainingexercisesftxrcocachievementsfr.js";
import OurWorkIndexRMIFC from "./rmifc/ourwork/ourworkindex.js";
import CapacityBuildingRMIFC from "./rmifc/ourwork/capacitybuilding.js";
import RMIFCLayout4 from "./components/Layout/rmifcourwork/rmifclayout4.js";

function AppRouter() {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "smooth";
    window.scrollTo(0, 0);

    // Optional: Reset scroll behavior after scroll
    const timeout = setTimeout(() => {
      document.documentElement.style.scrollBehavior = "auto";
    }, 500);

    return () => clearTimeout(timeout);
  }, [pathname]);

  return (
    <>
      <Routes>
        <>
          {/* Layout1 */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
          </Route>
          <Route path="/fr" element={<LayoutFR />}>
            <Route index element={<HomeFR />} />
          </Route>

          <Route path="*" element={<LayoutPageNotFound />}>
            <Route path="*" element={<Nomatch />} />
          </Route>

          {/* RCOCEN HOME PAGE */}
          <Route path="/" element={<RCOCLayout2 />}>
            <Route path="/rcoc" element={<RCOCHomePage />}></Route>
          </Route>
          {/* RCOCFR * HOMEPAAGE*/}
          <Route path="/" element={<RCOCLayout2FR />}>
            <Route path="/rcocfr" element={<RCOCHomePageFR />}></Route>
          </Route>

          {/* RCOCEN ABOUT US */}
          <Route path="/" element={<RCOCLayout3 />}>
            <Route path="/About Us/" element={<RCOCAboutUsIndex />}></Route>
            <Route path="/Director" element={<Director />}></Route>
            <Route path="/Ioc" element={<IOCComp />}></Route>
            <Route path="/Our Teams" element={<OurTeams />}></Route>
            <Route path="/Our Partners" element={<OurPartners />}></Route>
            <Route path="/Testimonials" element={<Testmonials />}></Route>
          </Route>
          {/* RCOCFR * ABOUT US*/}
          <Route path="/" element={<RCOCLayout3FR />}>
            <Route
              path="/À propos de nous"
              element={<RCOCAboutUsIndexFR />}
            ></Route>
            <Route path="/Directeur" element={<DirectorFR />}></Route>
            <Route path="/Iocfr" element={<IOCFR />}></Route>
            <Route path="/Nos équipes" element={<OurTeamsFR />}></Route>
            <Route path="/Nos partenaires" element={<OurPatnersFR />}></Route>
            <Route path="/Témoignages" element={<TestMonialsFR />}></Route>
          </Route>

          {/* RMIFCEN */}
          <Route path="/" element={<RMIFCLayout2 />}>
            <Route path="/rmifc" element={<RMIFCHomePage />}></Route>

            <Route path="/aboutusrmifc" element={<RMIFCAboutUsIndex />}></Route>
          </Route>

          {/* RMIFCFR */}
          <Route path="/" element={<RMIFCLayout2FR />}>
            <Route path="/rmifcfr" element={<RMIFCHomePageFR />}></Route>
            <Route
              path="/aboutusrmifcfr"
              element={<RMIFCAboutUsIndexFR />}
            ></Route>
          </Route>

          {/* RMIFCEN ABOUT US */}
          <Route path="/" element={<RMIFCLayout3 />}>
            <Route
              path="/About Us RMIFC/"
              element={<RMIFCAboutUsIndex />}
            ></Route>
            <Route path="/Director RMIFC" element={<Director />}></Route>
            <Route path="/Ioc RMIFC" element={<IOCComp />}></Route>
            <Route path="/Our Teams RMIFC" element={<OurTeams />}></Route>
            <Route path="/Our Partners RMIFC" element={<OurPartners />}></Route>
            <Route path="/Testimonials RMIFC" element={<Testmonials />}></Route>
          </Route>
          {/* RMIFCENFR ABOUT US */}
          <Route path="/" element={<RMIFCLayout3 />}>
            <Route
              path="/About Us RMIFCFR/"
              element={<RMIFCAboutUsIndexFR />}
            ></Route>
            <Route path="/Director RMIFCFR" element={<Director />}></Route>
            <Route path="/Ioc RMIFCFR" element={<IOCComp />}></Route>
            <Route path="/Our Teams RMIFCFR" element={<OurTeams />}></Route>
            <Route
              path="/Our Partners RMIFCFR"
              element={<OurPartners />}
            ></Route>
            <Route
              path="/Testimonials RMIFCFR"
              element={<Testmonials />}
            ></Route>
          </Route>

          {/* RCOCEN OUR WORK */}
          <Route path="/" element={<RCOCLayout4 />}>
            <Route path="/Our Work/" element={<OurWorkIndex />}></Route>
            <Route
              path="/Capacity Building/"
              element={<CapacityBuilding />}
            ></Route>
            <Route
              path="/Coordination of regional operations at sea"
              element={<CoordinationofRegionalOperationsatSea />}
            ></Route>
            <Route
              path="/Regional tabletop exercises TTX"
              element={<RegionalTableopExercisesTTX />}
            ></Route>
            <Route
              path="/Field Training Exercises FTX RCOC Achievements"
              element={<FieldTrainingExercisesFTXRCOCAchievements />}
            ></Route>
          </Route>

          {/* RCOCFR OUR WORK */}
          <Route path="/" element={<RCOCLayout4FR />}>
            <Route path="/Notre travail" element={<OurWorkIndexFR />}></Route>
            <Route
              path="/Renforcement des capacités"
              element={<CapacityBuildingFR />}
            ></Route>
            <Route
              path="/Coordination des opérations régionales en mer"
              element={<CoordinationofRegionalOperationsatSeaFR />}
            ></Route>
            <Route
              path="/Exercices régionaux sur table TTX"
              element={<RegionalTableopExercisesTTXFR />}
            ></Route>
            <Route
              path="/Exercices d'entraînement sur le terrain FTX Réalisations du RCOC"
              element={<FieldTrainingExercisesFTXRCOCAchievementsFR />}
            ></Route>
          </Route>

          {/* RMIFCEN OUR WORK */}
          <Route path="/" element={<RMIFCLayout4 />}>
            <Route
              path="/Our-Work-RMIFC/"
              element={<OurWorkIndexRMIFC />}
            ></Route>
            <Route
              path="/Capacity Building RMIFC/"
              element={<CapacityBuildingRMIFC />}
            ></Route>
            <Route
              path="/Coordination of regional operations at sea RMIFC"
              element={<CoordinationofRegionalOperationsatSea />}
            ></Route>
            <Route
              path="/Regional tabletop exercises TTX RMIFC"
              element={<RegionalTableopExercisesTTX />}
            ></Route>
            <Route
              path="/Field Training Exercises FTX RMIFC Achievements RMIFC"
              element={<FieldTrainingExercisesFTXRCOCAchievements />}
            ></Route>
          </Route>

          {/* RMIFCFR OUR WORK */}
          <Route path="/" element={<RMIFCLayout4 />}>
            <Route path="/Notre travail" element={<OurWorkIndexFR />}></Route>
            <Route
              path="/Renforcement des capacités"
              element={<CapacityBuildingFR />}
            ></Route>
            <Route
              path="/Coordination des opérations régionales en mer"
              element={<CoordinationofRegionalOperationsatSeaFR />}
            ></Route>
            <Route
              path="/Exercices régionaux sur table TTX"
              element={<RegionalTableopExercisesTTXFR />}
            ></Route>
            <Route
              path="/Exercices d'entraînement sur le terrain FTX Réalisations du RCOC"
              element={<FieldTrainingExercisesFTXRCOCAchievementsFR />}
            ></Route>
          </Route>
        </>
      </Routes>
    </>
  );
}

export default AppRouter;
