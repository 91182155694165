// NavBarData.js
const navLinks = [
  { title: "Home", path: "/rcocfr" },

  {
    title: "À propos de nous",
    path: "/À propos de nous",
    subLinks: [
      { name: "À propos de nous", link: "À propos de nous" },
      { name: "Directeur", link: "Directeur" },
      { name: "IOC", link: "Iocfr" },
      { name: "Nos équipes", link: "Nos équipes" },
      { name: "Nos partenaires", link: "Nos partenaires" },
      { name: "Témoignages", link: "Témoignages" },
    ],
  },
  {
    title: "Notre travail",
    path: "/Notre travail",
    subLinks: [
      {
        name: "Renforcement des capacités",
        link: "Renforcement des capacités",
      },
      {
        name: "Coordination des opérations régionales en mer",
        link: "Coordination des opérations régionales en mer",
      },
      {
        name: "Exercices régionaux sur table (TTX)",
        link: "Exercices régionaux sur table TTX",
      },
      {
        name: "Exercices d'entraînement sur le terrain (FTX) Réalisations du RCOC",
        link: "Exercices d'entraînement sur le terrain FTX Réalisations du RCOC",
      },
    ],
  },
  {
    title: "Centre des médias",
    path: "/Centre des médias",
    subLinks: [
      { name: "Actualités", link: "Actualités" },
      { name: "Blog", link: "Blog" },
      { name: "Galerie", link: "Galerie" },
      { name: "Vidéos", link: "Vidéos" },
    ],
  },
  {
    title: "Resources",
    path: "/Resources",
    subLinks: [
      { name: "Etudes de cas", link: "Etudes de cas" },
      { name: "Rapports généraux", link: "Rapports généraux" },
      { name: "Articles de recherche", link: "Articles de recherche" },
      { name: "Livres blancs", link: "Livres blancs" },
    ],
  },
  { title: "Événements", path: "/Événements" },
  {
    title: "Cadre de gouvernance",
    path: "/Cadre de gouvernance",
    subLinks: [
      { name: "Accords régionaux", link: "Accords régionaux" },
      { name: "Conseil des ministres", link: "Conseil des ministres" },
      { name: "Comité de pilotage", link: "Comité de pilotage" },
      { name: "États signataires", link: "États signataires" },
      { name: "Fonds fiduciaire", link: "Fonds fiduciaire" },
      { name: "Secrétariat", link: "Secrétariat" },
    ],
  },
  { title: "Contacteznous", path: "/Contactez nous" },
  { title: "[RMIFC Centre]", path: "/rmifcfr" },
];

export default navLinks;
