import React from "react";
import { Outlet } from "react-router-dom";
import FooterComp from "../../../rcoc/footer/footercomp";
import RcocHeader4 from "./rcocheader4";
import LeftMenus from "./leftMenusrcoc";

function RCOCLayout4() {
  return (
    <div>
      |<RcocHeader4 />
      <LeftMenus />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RCOCLayout4;
