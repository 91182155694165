import React from "react";
import { Outlet } from "react-router-dom";

import FooterComp from "../../../rmifc/footer/footercomp";
import RmifcHeader from "./rmifcheader";

function RMIFCLayout2() {
  return (
    <div>
      <RmifcHeader />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RMIFCLayout2;
