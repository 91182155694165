import React from "react";

import CapacityBuildingFR from "./capacitybuildingfr";

const OurWorkIndexFR = () => {
  return (
    <div>
      <CapacityBuildingFR />
    </div>
  );
};

export default OurWorkIndexFR;
