// NavBar.js

import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import navLinks from "./NavBarDatafr";
import navLinksMobile from "./NavBarDatamobile";
import "./NavBar.css";
import { ArrowDropDown } from "@mui/icons-material";

const MenuLinks = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 1) {
      setVisible(true);
    } else if (scrolled <= 1) {
      setVisible(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        !event.target.closest(".nav-linksmobile") &&
        !event.target.closest(".menu-icon")
      ) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const TitleLink = ({ link }) => {
    return (
      <div style={{ display: "flex" }}>
        {link}{" "}
        <div style={{ marginTop: "0.2rem" }}>
          <ArrowDropDown />
        </div>
      </div>
    );
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour 
      in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <nav className="navbar">
      <div className="logoDisplayMinimized">
        <Link href="/">
          <img
            src="/rmifc/rmifcmobilelogo.png"
            alt="Regional maritime Information Fusion Centre (RMIFC)"
          />
        </Link>

        <div
          className="menu-icon"
          onClick={(e) => {
            e.stopPropagation();
            toggleMenu();
          }}
        >
          <FaBars />
        </div>
      </div>

      <div>
        <ul className={`nav-links ${menuOpen ? "active" : ""}`}>
          {navLinks.map((link, index) => (
            <li key={index} onMouseLeave={closeMenu}>
              <Link onClick={scrollToTop} to={link.path}>
                {link.subLinks ? <TitleLink link={link.title} /> : link.title}
              </Link>
              {link.subLinks && (
                <ul className="sub-links">
                  {link.subLinks.map((subLink, subIndex) => (
                    <li key={subIndex}>
                      <Link to={`/${subLink.link}`}>{subLink.name}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      <ul className={`nav-linksmobile ${menuOpen ? "active" : ""}`}>
        {navLinksMobile.map((menu, index) => (
          <li key={index}>
            <Link
              onClick={(e) => {
                e.stopPropagation();
                scrollToTop();
                closeMenu();
              }}
              to={menu.path}
            >
              {menu.title}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default MenuLinks;
