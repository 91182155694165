import React from "react";
import { Outlet } from "react-router-dom";
import LanguageBar from "../../LanguageBar/languagebar";

import FooterFR from "../../footer/footerfr";
import HeaderFR from "../../header/headerfr";
import HomeSliderFr from "../../homeslider/homesliderfr";

function LayoutFR() {
  return (
    <div>
      <LanguageBar />
      <HeaderFR />
      <HomeSliderFr />
      <div>
        <Outlet />
      </div>
      <FooterFR />
    </div>
  );
}

export default LayoutFR;
