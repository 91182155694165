import React from "react";
import AboutUs from "./homepage/aboutusfr";
import RCOCDIRECRORSFR from "./homepage/directorsfr";
import RCOCCOORDINATORFR from "./homepage/coordinatorFR";
import RCOCMissionFR from "./homepage/ourmission/rcocmissionFR";
import RCOCMemberStatesFR from "./homepage/memberstate/memberstatesfr";
import RCOCPartnerILOFR from "./homepage/partnerILO/partnerilofr";
import RCOCNEWSFR from "./homepage/newsbytes/rcocnewsfr";
import RCOCFeaturedStoryFR from "./homepage/featuredstory/featuredstoryfr";
import RCOCMemberStatesFlagsFR from "./homepage/memberstate/memberstatesflagsfr";
import RCOCPartnerFlagsFR from "./homepage/partnerILO/partnerilofr";
import SignUpFR from "./signup/signupfr";
import HomeSliderFR from "../rcoc/slider/homesliderfr";

const RCOCHomePageFR = () => {
  return (
    <div>
      <HomeSliderFR />
      <AboutUs />
      <RCOCDIRECRORSFR />
      <RCOCCOORDINATORFR />
      <RCOCMissionFR />
      <RCOCMemberStatesFR />
      <RCOCPartnerILOFR />
      <RCOCNEWSFR />
      <RCOCFeaturedStoryFR />
      <RCOCMemberStatesFlagsFR />
      <RCOCPartnerFlagsFR />
      <SignUpFR />
    </div>
  );
};

export default RCOCHomePageFR;
