import React from "react";

import LanguageBarCenters from "../../LanguageBar/rcoclanguagebarcenters";
import Styles from "./header.module.css";
import NavBarFR from "../../../rcoc/navbar/navbarfr";
function RcocHeaderFR() {
  return (
    <div className={Styles.mainContainer}>
      <LanguageBarCenters />
      <NavBarFR />
    </div>
  );
}

export default RcocHeaderFR;
