// NavBarData.js
const navLinks = [
  { title: "Home", path: "/rcoc" },

  {
    title: "About Us",
    path: "/About Us",
    subLinks: [
      {name:"About Us",link:"About Us"},
      {name:"Director",link:"Director"},
      {name:"IOC",link:"IOC"},
      {name:"Our teams",link:"Our Teams"},
      {name:"Our partners",link:"Our Partners"},
      { name: "Testimonials", link: "Testimonials" },
    ],
  },
  {
    title: "Our Work",
    path: "/Our Work",
    subLinks: [
      {name:"Capacity building",link:"Capacity building"},
      {name:"Coordination of regional operations at sea",link:"Coordination of regional operations at sea"},
      {name:"Regional tabletop exercises (TTX)",link:"Regional tabletop exercises TTX"},
      {name:"Field Training Exercises (FTX) RCOC Achievements",link:"Field Training Exercises FTX RCOC Achievements"},
    ],
  },
  {
    title: "Media Center",
    path: "/Media Center",
    subLinks: [{name:"News",link:"News"}, {name:"Blog",link:"Blog"}, {name:"Gallery",link:"Gallery"}, {name:"Videos",link:"Videos"}],
  },
  {
    title: "Resources",
    path: "/Resources",
    subLinks: [
      {name:"Case studies",link:"Case studies"},
      {name:"General reports",link:"General reports"},
      {name:"Research articles",link:"Research articles"},
      {name:"White Papers",link:"White Papers"},
    ],
  },
  { title: "Events", path: "/Events" },
  {
    title: "Governance Framework",
    path: "/Governance Framework",
    subLinks: [
      {name:"Regional agreements",link:"Regional agreements"},
      {name:"Council of Ministers",link:"Council of Ministers"},
      {name:"Steering committee",link:"Steering committee"},
      {name:"Signatory States",link:"Signatory States"},
      {name:"Trust fund",link:"Trust fund"},
      {name:"Secretariat",link:"Secretariat"},
    ],
  },
  { title: "Contact us", path: "/Contact us" },
  { title: "[RMIFC Centre]", path: "/rmifc" },
];

export default navLinks;
