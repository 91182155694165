import React from "react";
import MainMenu from "./mainmenufr";
import MenuLinks from "./MenuLinksfr";

const NavBarFR = () => {
  return (
    <>
      <div >
        <MainMenu />
      </div>
      <MenuLinks />
    </>
  );
};

export default NavBarFR;
