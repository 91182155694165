import React from "react";
import { Link, useLocation } from "react-router-dom";
import Styles from "./leftmenus.module.css";

const LeftMenusFR = () => {
  const location = useLocation();
  const rawPath = decodeURIComponent(location.pathname.replace(/^\/+/, ""));

  // Treat these paths as "Home"
  const normalizedPath =
    rawPath === "" || rawPath === "rcocfr" || rawPath === "À propos de nous"
      ? "À propos de nous"
      : rawPath;

  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.left}>
        <div className={Styles.menu}>
          {menuItems.map((item, index) => {
            const isActive = normalizedPath === item.menuLink;
            return (
              <Link to={`/${item.menuLink}`} key={index}>
                <li className={isActive ? Styles.active : ""}>
                  {item.menuName}
                </li>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LeftMenusFR;

const menuItems = [
  { menuName: "Home", menuLink: "rcocfr" },
  { menuName: "À propos de nous", menuLink: "À propos de nous" },
  { menuName: "Directeur", menuLink: "Directeur" },
  { menuName: "IOC", menuLink: "Iocfr" },
  { menuName: "Nos équipes", menuLink: "Nos équipes" },
  { menuName: "Nos partenaires", menuLink: "Nos partenaires" },
  { menuName: "Témoignages", menuLink: "Témoignages" },
];
