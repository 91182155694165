import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

function DirectorFR() {
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.left}>
        <div className={Styles.menu}>
          {menuItems.map((item, index) => (
            <Link to={item}>
              {" "}
              <li key={index}>{item}</li>
            </Link>
          ))}
        </div>
      </div>
      <div className={Styles.right}>
        <h1>Director</h1>
        <p>
          Le RCCO a été créé dans le cadre du Conseil Régional Accord sur la
          coordination des opérations offshore dans l'Ouest Océan Indien
          développé dans le cadre du programme MASE mis en œuvre par le
          Commission de l'Océan Indien avec un financement de l'Union
          européenne. Le Union des Comores, la République de Djibouti, la
          République de Madagascar, la République de Maurice et la République
          des Seychelles, ont signé l’accord régional en avril 2018. Deux autres
          pays ont adhéré à l’Accord en novembre 2018, la République française
          et le République du Kenya.{" "}
        </p>

        <p>
          Les parties signataires de l'accord étaient convenues d'établir un
          système de coordination opérationnelle régionale avec les éléments
          suivants objectifs :
        </p>
        {Objectives.map((objective, index) => (
          <li key={index}>{objective}</li>
        ))}
        <p>
          Pour la mise en œuvre de l'Accord, les Opérations Régionales Le centre
          de coordination (RCCO) a été créé.{" "}
        </p>
        <p>
          Le RCCO jouit de la personnalité juridique au plan national et
          international. la loi, ainsi que les protections, privilèges et
          immunités accordés comme le prévoit l'accord de siège signé le 1er
          juillet 2017 entre les Commission de l'Océan Indien et République des
          Seychelles
        </p>
        <p>
          Il travaille en étroite collaboration avec le Centre Régional de
          Fusion d'Informations (CRFIM), basé à Madagascar.
        </p>
        <p>
          Chaque État partie nomme un officier de liaison au sein du RCCO, en
          afin d'établir un lien entre les structures nationales et les Centre.{" "}
        </p>
        <p>
          Le Centre est régi par les règles indiquées par la « Gouvernance des
          Centres régionaux". Le RCCO a été créé en 2017 et n'est devenu
          pleinement opérationnel en 2019 après l’affectation de l’agent de
          liaison officiers.
        </p>
      </div>
    </div>
  );
}

export default DirectorFR;

const menuItems = [
  "About Us",
  "IOC",
  "Our Teams",
  "Our Partners",
  "TestMonials",
];

const Objectives = [
  "To promote cooperation and coordination to enable the parties' law enforcement officials, vessels and aircraft to conduct coordinated missions of regional interest in maritime areas under the jurisdiction of the parties and on the high seas in accordance with international law, to their legislature",
  "Strengthen individual and institutional capacities through training and experience exchange programmes",
  " Organize joint and/or coordinated interventions at sea and in the surrounding space to prevent and stop acts or situations affecting maritime safety and security",
  " To enable and facilitate mutual support between States in the region when an international or regional maritime security interest is affected in the regional area of operation",
  "Promote cooperation with other agencies operating in the field of maritime safety and security.",
];
