import React from "react";
import NavBarFR from "../../../rcoc/navbar/navbarfr";
import LanguageBarCenters from "../../LanguageBar/rcoclanguagebarcenters";
import Styles from "./header.module.css";

function RcocHeader4FR() {
  return (
    <div className={Styles.mainContainer}>
      <LanguageBarCenters />
      <NavBarFR />
    </div>
  );
}

export default RcocHeader4FR;
