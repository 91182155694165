import React from "react";
import "./allmenus.css";
import MainMenu from "./mainmenu";
import MenuLinks from "./MenuLinks";
import MobileMenu from "./mobilemenu";

const NavBar = () => {
  return (
    <div >
      <MobileMenu />
      <div>
        <MainMenu />
      </div>
      <MenuLinks />
    </div>
  );
};

export default NavBar;
