import React from "react";
import { Outlet } from "react-router-dom";

import FooterComp from "../../../rmifc/footer/footercompfr";
import RmifcHeaderFR from "./rmifcheaderfr";

function RMIFCLayout2FR() {
  return (
    <div>
      <RmifcHeaderFR />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RMIFCLayout2FR;
