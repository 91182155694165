import React from "react";
import { Link, useLocation } from "react-router-dom";
import Styles from "./leftmenus.module.css";

const LeftMenus = () => {
  const location = useLocation();
  const rawPath = decodeURIComponent(location.pathname.replace(/^\/+/, ""));

  // Treat these paths as "Home"
  const normalizedPath =
    rawPath === "" || rawPath === "rcoc" || rawPath === "Our Work"
      ? "rcoc"
      : rawPath;

  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.left}>
        <div className={Styles.menu}>
          {menuItems.map((item, index) => {
            const isActive = normalizedPath === item.menuLink;
            return (
              <Link to={`/${item.menuLink}`} key={index}>
                <li className={isActive ? Styles.active : ""}>
                  {item.menuName}
                </li>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LeftMenus;

const menuItems = [
  { menuName: "Home", menuLink: "rcoc" },
  { menuName: "Capacity building", menuLink: "Capacity building" },
  {
    menuName: "Coordination of regional operationsb at sea",
    menuLink: "Coordination of regional operations-at-sea",
  },
  {
    menuName: "Regional tabletop exercises (TTX)",
    menuLink: "Regional tabletop exercises TTX",
  },
  {
    menuName: "Field Training Exercises (FTX) RCOC Achievements",
    menuLink: "Field Training Exercises FTX RCOC Achievements",
  },
];
