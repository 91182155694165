import React from "react";
import NavBarFR from "../../../rmifc/navbar/navbarfr";
import LanguageBarCenters from "../../LanguageBar/rmifclanguagebarcenters";
import Styles from "./header.module.css";

function RmifcHeaderFR() {
  return (
    <div className={Styles.mainContainer}>
      <LanguageBarCenters />
      <NavBarFR />
    </div>
  );
}

export default RmifcHeaderFR;
