import React from "react";
import Styles from "./Styles.module.css";
import { Link } from "react-router-dom";

function LanguageBarCenters() {
  return (
    <div>
      <div className={Styles.mainContainer}>
        <Link to="/rcoc">
          <p style={{ fontWeight: "bold" }}>English</p>
        </Link>
        <Link to="/rcocfr">
          <p>French</p>
        </Link>
      </div>
    </div>
  );
}

export default LanguageBarCenters;
