import React from "react";
import { Outlet } from "react-router-dom";

import FooterComp from "../../../rcoc/footer/footercompfr";
import RcocHeaderFR from "./rcocheaderfr";

function RCOCLayout2FR() {
  return (
    <div>
      <RcocHeaderFR />
      <div>
        <Outlet />
      </div>
      <FooterComp />
    </div>
  );
}

export default RCOCLayout2FR;
